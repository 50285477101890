
.rlscFooter {
    position: relative;
    background: var(--rlsc--colors--primary);
    padding: var(--rlsc--spacing--extra-large);
    display: flex;
    flex-direction: column;
}

.rlscFooter__cnt {
    flex: 1;
    display: flex;
    flex-direction: row;
}
.rlscFooter__spacer {
    flex: 1;
}
.rlscFooter__section {
    flex: 1;
    padding: var(--rlsc--spacing--extra-large);
}
.rlscFooter__section__title{
    padding: var(--rlsc--spacing--extra-large) 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 24px;
    text-decoration: none;
    color: var(--rlsc--colors--background--surface);
    font-family: var(--rlsc--typography--title--1--font-family);
    font-size: var(--rlsc--typography--title--1--font-size);
    font-weight: var(--rlsc--typography--title--1--font-weight);
}

.rlscFooter__title {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 24px;
    text-decoration: none;
    font-family: var(--rlsc--typography--header--1--font-family);
    font-size: var(--rlsc--typography--header--1--font-size);
    font-weight: var(--rlsc--typography--header--1--font-weight);
    color: var(--rlsc--colors--background--surface);
}
.rlscFooter__title__logo {
    /* width: 48px; */
    height: 48px;
    margin: 0 var(--rlsc--spacing--extra-large);
}
.rlscFooter__spacer {
    flex: 1;
    width: 100%;
}
.rlscFooter__subtitle {
    font-size: 24px;
    text-align: center;
}
@media only screen and ( max-width: 620px ) {
    .rlscFooter__cnt, .rlscFooter__title {
        flex-direction: column;
        justify-items: center;
        align-self: center;
    }
    .rlscFooter__subtitle {
        font-size: 24px;
        margin-top: var(--rlsc--spacing--extra-large);;
    }
    .rlscFooter__section {
        text-align: center;
        flex-direction: column;
        width: 200px;
        margin: 0 auto;
    }
    .rlscHeader__title__logo {
        height: 36px;
    }
}