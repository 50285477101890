.rlscBgPattern{
    background-color: #e5e5f7;
    opacity: 0.24;
    background-image:  linear-gradient(#2948b1 1px, transparent 1px), linear-gradient(to right, #2948b1 1px, #e5e5f7 1px);
    background-size: 20px 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
}
.rlscBgPattern1{
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(255, 255, 255, 0.84) 0%, rgba(241, 243, 252, 0.84) 18%, rgba(177, 191, 237, 0.8) 52%, rgb(41, 72, 177, 0.84) 80%, rgba(41, 72, 177) 99%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255, 255, 255, 0.84) 0%,rgba(241, 243, 252, 0.84) 18%,rgba(177, 191, 237, 0.8) 52%, rgb(41, 72, 177, 0.84) 80%, rgba(41, 72, 177) 99%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255, 255, 255, 0.84) 0%,rgba(241, 243, 252, 0.84) 18%,rgba(177, 191, 237, 0.8) 52%, rgb(41, 72, 177, 0.84) 80%,rgba(41, 72, 177) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#2948b1',GradientType=0 ); /* IE6-9 */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* opacity: 0.8; */
    z-index: -2;
}
.rlscFormHost {
    position: relative;
    z-index: 1;
    overflow: auto;
    height: 720px;
    justify-content: center;
    display: flex;
    overflow-y: auto;
    width: 100%;
    padding-top: 72px;
    background: rgba(41, 72, 177);
}

.rlscRegisterInterestForm {
    height: 520px;
    width: 420px;
    border-radius: var(--rlsc--spacing--extra-large);
    background: rgba(255,255,255, 0.6);
    padding: var(--rlsc--spacing--medium);
    margin: var(--rlsc--spacing--huge);
    display: flex;
    flex-direction: column;
}
.rlscComingSoon {
    height: 520px;
    width: 620px;
    font-family: var(--rlsc--typography--subtitle--1--font-family);
    font-size: var(--rlsc--typography--title--1--font-size);
    font-weight: 10px;
    color: var(--rlsc--typography--body--1--color);
    /* justify-content: center; */
    padding: var(--rlsc--spacing--medium);
    margin: var(--rlsc--spacing--huge);
    align-items: center;
    display: flex;
    flex-direction: column;
    color: var(--rlsc--colors--background--surface);
    text-align: center;
}
.rlscRegisterInterestForm_img {
    width: 220px;
    margin: auto;
}
.rlsc__comingSoon__title, .rlsc__comingSoon__desc {
    font-family: var(--rlsc--typography--header--1--font-family);
    font-size: 64px;
    color: var(--rlsc--colors--secondary);
    margin-bottom: var(--rlsc--spacing--huge);
}
.rlsc__comingSoon__desc {
    font-size: 24px;
    color: var(--rlsc--colors--background--surface)
}
.rlsc__logo {
    width: 200px;
}
.rlsc__logo__1 {
    width: 300px;
}

@media only screen and ( max-width: 870px ) {
    
    .rlscFormHost {
        flex-direction: column;
        align-items: center;
        padding-top: 8px;
        height: auto;
        background: rgba(41, 72, 177);
    }
    .inputHost {
        display: flex;
        flex-direction: column;
    }
    .rlscRegisterInterestForm {
        height: auto;
        width: 320px;
        border-radius: var(--rlsc--spacing--extra-large);
        background: rgba(255,255,255, 0.84);
        padding: var(--rlsc--spacing--medium);
        margin: var(--rlsc--spacing--huge);
        display: flex;
        flex-direction: column;
    }
    .rlscComingSoon {
        height: 300px;
        width: 320px;
        font-family: var(--rlsc--typography--subtitle--1--font-family);
        font-size: var(--rlsc--typography--title--1--font-size);
        font-weight: 10px;
        color: var(--rlsc--typography--body--1--color);
        /* justify-content: center; */
        padding: var(--rlsc--spacing--medium);
        margin: var(--rlsc--spacing--huge);
        align-items: center;
        display: flex;
        flex-direction: column;
        color: var(--rlsc--colors--background--surface);
        text-align: center;
    }
    .rlscRegisterInterestForm_img {
        width: 220px;
        margin: auto;
    }
    .rlsc__comingSoon__title, .rlsc__comingSoon__desc {
        font-size: 24px;
        color: var(--rlsc--colors--secondary);
        margin-bottom: var(--rlsc--spacing--huge);
    }
    .rlsc__comingSoon__desc {
        font-size: 20px;
    }
    .rlsc__logo {
        width: 120px;
    }
    .rlsc__logo__1 {
        width: 300px;
    }
}

.rlsc__spacer {
    flex: 1;
}

.rlscFormBtnHost {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.rlscFormCnt {
    padding:var(--rlsc--spacing--extra-large);
    flex: 1;
}
.inputHost {
    display: flex;
    flex-direction: row;
}
.rlscFormTitle {
    font-family: var(--rlsc--typography--subtitle--1--font-family);
    font-size: var(--rlsc--typography--title--1--font-size);
    font-weight: 10px;
    color: var(--rlsc--colors--secondary);
    text-align: center;
    padding: var(--rlsc--spacing--medium);
}


.inputText {
    height: 25px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: var(--rlsc--spacing--medium);
    padding: var(--rlsc--spacing--small) var(--rlsc--spacing--medium);
    font-size: 14px;
}
.inputLabel {
    font-family: var(--rlsc--typography--subtitle--1--font-family);
    font-size: 14px;
    font-weight: var(--rlsc--typography--subtitle--1--font-weight);
    color: var(--rlsc--typography--body--1--color);
}
.helperText, .errorText {
    font-family: var(--rlsc--typography--subtitle--1--font-family);
    font-size: 12px;
    font-weight: var(--rlsc--typography--subtitle--1--font-weight);
    color: var(--rlsc--colors--primary);
}
.errorText {
    color: var(--rlsc--colors--secondary);
    
}
.listContainer {
    display: flex;
}

.error {
    color: red;
    font-size: 12px;
}

.title {
    font-size: 25px;
    margin-bottom: 20px;
}
.thankYouContainer {
    padding:var(--rlsc--spacing--extra-large);
    height: 180px;
    flex: 1;
}
.inputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 8px;
    flex:1;
}
.responseText {
    padding:var(--rlsc--spacing--extra-large);
    text-align: center;
    color: var(--rlsc--colors--text--primary-theme-light);
    font-family: var(--rlsc--typography--subtitle--1--font-family);
    /* font-size: var(--rlsc--typography--subtitle--1--font-size); */
}
.subTitleFormText {
    font-family: var(--rlsc--typography--subtitle--1--font-family);
    font-size: 14px;
    font-weight: var(--rlsc--typography--subtitle--1--font-weight);
    color: var(--rlsc--colors--primary);
    margin-top:var(--rlsc--spacing--small);
}
