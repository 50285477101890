.rlscLinkButtonHost {
    position: relative;
}
.rlscLinkButton {
    display: flex;
    flex-direction: row;
    cursor:pointer;
    padding: var(--rlsc--spacing--medium) var(--rlsc--spacing--medium);
    text-decoration: none;
}
.rlscLinkButton__title {
    font-family: var(--rlsc--typography--subtitle--1--font-family);
    font-size: var(--rlsc--typography--subtitle--1--font-size);
    font-weight: var(--rlsc--typography--subtitle--1--font-weight);
}
.rlscLinkButton:hover {
    background: var(--rlsc--colors--background-body);
    color: var(--rlsc--colors--primary);
}

.rlscLinkButton__primary {
    color: var(--rlsc--colors--primary);
}
.rlscLinkButton__primary__title {
    color: var(--rlsc--colors--secondary);
}
.rlscLinkButton__primary:hover {
    background: var(--rlsc--colors--background-body);
    color: var(--rlsc--colors--primary);
}

.rlscLinkButton__secondary {
    color: var(--rlsc--colors--secondary);
}
.rlscLinkButton__secondary__title {
    color: var(--rlsc--colors--secondary);
}
.rlscLinkButton__secondary:hover {
    background: var(--rlsc--colors--background-body);
    color: var(--rlsc--colors--primary);
}

.rlscLinkButton__disabled {
    color: var(--rlsc--colors--disabled);
    cursor: not-allowed;
}
.rlscLinkButton__disabled__title {
    color: var(--rlsc--colors--disabled);
}
.rlscLinkButton__disabled:hover {
    background: var(--rlsc--colors--background-body);
    color: var(--rlsc--colors--primary);
}