.rlscHeaderHost {
    width: 100%;
    height: var(--rlscHeader--height, 64px);
    min-height: var(--rlscHeader--height, 64px);
    background: var(--rlsc--colors--background--surface);
    position: relative;
}
.rlscHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
   
    padding: 0 var(--rlsc--spacing--extra-large);
    height: 100%;
}
.rlscHeader__title {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 24px;
    text-decoration: none;
    font-family: var(--rlsc--typography--header--1--font-family);
    font-size: var(--rlsc--typography--header--1--font-size);
    font-weight: var(--rlsc--typography--header--1--font-weight);
}
.rlscHeader__title__logo {
    /* width: 48px; */
    height: 48px;
    margin: 0 var(--rlsc--spacing--extra-large);
}
.rlscHeader__spacer {
    flex: 1;
    width: 100%;
}
.rlscHeader__options {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
}
@media only screen and ( max-width: 620px ) {
    .rlscHeader__options {
        display: none;
    }
    .rlscHeader__title__logo {
        height: 36px;
    }
}