html,
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: auto;
}
body {
    --rlsc--header--height: 64px;
    --rlsc--gutter--full: 16px;
    --rlsc--gutter--half: 16px;
    --rlsc--colors--primary: #2948B1;
    --rlsc--colors--primary-tint: #b1bfed;
    --rlsc--colors--secondary: #FF2F92;
    --rlsc--colors--disabled: #888888;
    --rlsc--colors--primary-light: #02c2d2;
    --rlsc--colors--background-body: #f3f4f4;
    --rlsc--colors--background--surface: #fff;
    --rlsc--colors--background--surface-theme-light: #fff;
    --rlsc--colors--text--primary: #283636;
    --rlsc--colors--text--primary-theme-light: #283636;
    --rlsc--spacing--small: 4px;
    --rlsc--spacing--medium: 8px;
    --rlsc--spacing--large: 12px;
    --rlsc--spacing--extra-large: 16px;
    --rlsc--spacing--huge: 24px;
    --rlsc--typography--header--1--color: #283636;
    --rlsc--typography--header--1--color-theme-light: #283636;
    --rlsc--typography--header--1--font-family: "Helvetica Nueve", sans-serif;
    --rlsc--typography--header--1--font-size: 24px;
    --rlsc--typography--header--1--font-weight: 400;
    --rlsc--typography--header--1--line-height: 16px;

    
    --rlsc--typography--title--1--font-family: "Helvetica Nueve", sans-serif;
    --rlsc--typography--title--1--font-size: 20px;
    --rlsc--typography--title--1--font-weight: 600;
    --rlsc--typography--title--1--line-height: 20px;

    --rlsc--typography--subtitle--1--font-family: "Helvetica Nueve", sans-serif;
    --rlsc--typography--subtitle--1--font-size: 16px;
    --rlsc--typography--subtitle--1--font-weight: 400;
    --rlsc--typography--subtitle--1--line-height: 20px;

    --rlsc--typography--body--1--color: #283636;
    --rlsc--typography--body--1--color-theme-light: #283636;
    --rlsc--typography--body--1--font-family: "Helvetica Nueve", sans-serif;
    --rlsc--typography--body--1--font-size: 12px;
    --rlsc--typography--body--1--font-weight: 400;
    --rlsc--typography--body--1--line-height: 16px;
    --rlsc--typography--header--color: #283636;
    --rlsc--typography--header--font-family: "Helvetica Nueve", sans-serif;
    --rlsc--typography--header--font-size: 13px;
    --rlsc--typography--header--font-weight: 600;
    --rlsc--typography--header--line-height: 20px;
    --rlsc--tile--margin: 30px;
    --rlsc--tile--margin-bottom: 23px;
    --rlsc--tile--text--secondary-color: #4a4a4a;
    --rlsc--tile--text--primary-color: #000000;
    --rlsc--tile--text--primary-font-family: "HelveticaNeue-Bold", "sans-serif";
    --rlsc--tile--text--secondary-font-family: "HelveticaNeue", "sans-serif";
    position: relative;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: auto;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
}

.flex--row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.flex--row--rev {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.flex--col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.flex--left {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.flex--center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flex--right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.flex--justify {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.flex--spaced {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.flex--top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.flex--middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex--bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.flex--stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.flex--baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

.flex__item {
    min-height: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}


.rlsc--button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    
    
    padding: var(--rlsc--spacing--medium);
    margin: var(--rlsc--spacing--medium);
    outline: none;
    border-radius: var(--rlsc--spacing--medium);
    min-width: 124px;
    min-height: 32px;
}
.rlsc--button__empty {
    background: var(--rlsc--colors--background--surface);
    border: 1px solid var(--rlsc--colors--primary);
}
.rlsc--button__empty:hover {
    background: var(--rlsc--colors--secondary);
    color: var(--rlsc--colors--secondary);
}

.rlsc--button__solid {
    border: 1px solid var(--rlsc--colors--primary);
    background: var(--rlsc--colors--primary);
}
.rlsc--button__solid:hover {
    background: var(--rlsc--colors--secondary);
}
.rlsc--button__empty  .rlsc--button__title{
    color: var(--rlsc--colors--primary)
}
.rlsc--button__solid  .rlsc--button__title{
    color: var(--rlsc--colors--background--surface);
}
.rlsc--button__title {
    font-family: var(--rlsc--typography--subtitle--1--font-family);
    font-size: var(--rlsc--typography--subtitle--1--font-size);
    font-weight: var(--rlsc--typography--subtitle--1--font-weight);
}
.rlsc--header--spacer {
    flex: 1;
}

.rlscFormHost {
    position: relative;
    background: var(--rlsc--colors--background-body, #e7e9e9);
    overflow: auto;
    justify-content: center;
    display: flex;
    overflow-y: auto;
    width: 100%;
}