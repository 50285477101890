.rlscLoadMaskHost {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rlscLoadMaskTitle {
    font-family: var(--rlsc--typography--subtitle--1--font-family);
    font-size: var(--rlsc--typography--subtitle--1--font-size);
    font-weight: 10px;
    color: var(--rlsc--typography--body--1--color);
    text-align: center;
    padding: var(--rlsc--spacing--medium);
}
