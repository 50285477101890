.rlscLogin{
    width: 100%;
    position: relative;
    background: var(--rlsc--colors--background-body, #e7e9e9);
    height: 100vh;
    overflow: auto;
    flex-direction: column;
    display: flex;
}

.rlscLoginFormHost {
    position: relative;
    background: var(--rlsc--colors--background-body, #e7e9e9);
    overflow: auto;
    overflow-y: auto;
    flex: 1;
}