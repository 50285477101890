:host {
    position: relative;
}
.rlscButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    
    
    padding: var(--rlsc--spacing--medium);
    margin: var(--rlsc--spacing--medium);
    outline: none;
    border-radius: var(--rlsc--spacing--medium);
    min-width: 124px;
    min-height: 32px;
}
.rlscButton__empty {
    background: var(--rlsc--colors--background--surface);
    border: 1px solid var(--rlsc--colors--primary);
}
.srlscButton__empty:hover {
    background: var(--rlsc--colors--secondary);
    color: var(--rlsc--colors--secondary);
}

.rlscButton__primary {
    border: 1px solid var(--rlsc--colors--primary);
    background: var(--rlsc--colors--primary);
}
.rlscButton__primary:hover {
    background: var(--rlsc--colors--secondary);
    border-color: var(--rlsc--colors--secondary);
}
.rlscButton__empty  .rlscButton__title{
    color: var(--rlsc--colors--primary)
}
.rlscButton__primary  .rlscButton__title{
    color: var(--rlsc--colors--background--surface);
}
.rlscButton__title {
    font-family: var(--rlsc--typography--subtitle--1--font-family);
    font-size: var(--rlsc--typography--subtitle--1--font-size);
    font-weight: var(--rlsc--typography--subtitle--1--font-weight);
}
.rlsc--header--spacer {
    flex: 1;
}

.rlscButton__icon {
    margin: 0 8px;
}


.rlscButton__secondary {
    border: 1px solid var(--rlsc--colors--secondary);
    background: var(--rlsc--colors--secondary);
}
.rlscButton__secondary:hover {
    background: var(--rlsc--colors--primary);
    border-color: var(--rlsc--colors--primary);
}
.rlscButton__empty  .rlscButton__title{
    color: var(--rlsc--colors--secondary)
}
.rlscButton__secondary  .rlscButton__title{
    color: var(--rlsc--colors--background--surface);
}